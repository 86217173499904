<template>
  <div class="container">
  
      <div class="title">{{detail.name}}</div>
      <div class="flex tip0 ">
          <div class="date">{{detail.date}}</div>
          <div class="num">{{detail.num}}人看过</div>
      </div>
       <el-divider></el-divider>
      <div class="content" v-html="detail.intro">
          
      </div>
       
  
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
    
      detail: {
          pic: require("@img/linshi/1.png"),
          name: "",
          date:"",
          num:0,
          intro:""
      }
    };
  },
  computed: {},
  mounted(){
    var id=this.$route.query.id;
    this.$http.newsdetail({
         id 
        }).then(res=>{
          this.detail.name=res.data.title;
          this.detail.date=res.data.rtime;
          this.detail.num=res.data.pv;
          this.detail.intro=res.data.content;
          this.detail.pic=res.data.thumb;
  
        })
  },
  methods: {
     
  },
};
</script>
<style scoped lang="scss">
@import "~@/styles/mixin.scss";
.container {
  margin-bottom: 20px;
  background-color: #fff;
}
.title {
  font-size: 24px;
  color:$color-theme;
  text-align: center;
  padding-top: 45px;
}
.tip0{
  width: 30%;
  justify-content: space-around;
  margin-top: 30px;
  color: #666;
  font-size: 16px;
 margin-left: 70%;
}
/deep/.content{
  padding: 20px ;
  font-size: 16px;
  line-height: 2;
  img{
    display: block;
    width: 100%;
   
    height: auto;
    margin: 0 auto;
  }
}
</style>
